<template lang="pug">
  div
    v-row
      v-col(cols="3")
        v-toolbar(
          :color="editMode ? 'yellow darken-3' : 'primary'"
          dark
          dense
          elevation="0"
        )
          span {{ editMode ? 'Edit' : 'New' }}
        create-feeding-program(
          :default-field-values.sync="editFeedingProgramData"
        )
      v-col(cols="9")
        v-toolbar(
          color="primary"
          dark
          dense
          elevation="0"
        )
          span List
        table-feeding-program(
          v-model="editFeedingProgramData"
        )
</template>
<script>
export default {
  name: 'FeedingProgram',
  components: {
    createFeedingProgram: () => import('./Create'),
    tableFeedingProgram: () => import('./Table'),
  },
  data: () => ({
    editFeedingProgramData: {},
  }),
  computed: {
    editMode () {
      return !this.$objectEmpty(this.editFeedingProgramData)
    },
  },
}
</script>